<template>
    <div class="results-wrapper">
        <mwc-fm-results-simplified v-if="sourceAPI === finametricaSourceAPI" :title-hidden="false" :region="region"
            :mwc-id="resultComponent" :response-id="this.fmrtqResponseId" :client-id="this.fmrtqClientId"
            :risk-tolerance-view="riskToleranceView" :languageId="lang">
        </mwc-fm-results-simplified>
        <mwc-esg-results v-if="sourceAPI === esgSourceAPI" :mwc-id="resultComponent" :response-id="this.fmrtqResponseId"
            :client-id="this.fmrtqClientId" ></mwc-esg-results>
    </div>
</template>

<script>
import "mwc-fm-results-simplified";
import "mwc-esg-results";
import { mapState, mapGetters } from "vuex";
import labels from "../config/labels.json";
import AppConstants from '../constants/constants.json';
import resultMixin from '../mixins/results';

import { redirectToErrorPage } from '../utils/redirectToErrorPage';

export default {
    name: "results",
    mixins: [resultMixin],
    props: {
        isClient: {
            type: Boolean,
            default: true
        },
        sourceAPI:{
            type: String,
        },
        riskToleranceView: {
            type: Boolean,
            default: false
        },
        region: {
          type: String,
          default: ''
        },
        lang: {
          type: String,
          default: ''
        }
    },
    data() {
        return {
            labels: labels,
            showButton: false,
            isLoading: true,
            resultComponent: AppConstants.sourceAPIComponentMappings[this.sourceAPI]
        };
    },

    created() {
        if (this.notificationDetails) {
            if (this.resultComponent) {
                window.mwc.configuration.setAppConfig({
                    components: {
                        [this.resultComponent]: {
                            settings: {
                                useDataManager: true,
                                sourceApi: this.sourceAPI,
                            },
                            components: {
                                dataManager: {
                                    settings: {
                                        additionalParameters: {
                                            hostEnvironment: (process.env.NODE_ENV === 'dr' ? 'prod' : process.env.NODE_ENV),
                                        },
                                    },
                                },
                            },
                        },
                    },
                });
            } else {
                redirectToErrorPage(labels[this.language].invalidResults, this.language);
            }
        } else {
            redirectToErrorPage(labels[this.language].somethingWentWrong, this.language);
        }
    },

    computed: {
        ...mapState(`rtqStore`, [
            `fmrtqClientId`,
            `fmrtqResponseId`
        ]),
        ...mapGetters(`rtqStore`, [
            `fmrtqClientId`,
            `fmrtqResponseId`
        ]),
        ...mapState(`authStore`, [
            `token`,
        ]),
        ...mapGetters(`authStore`, [
            `token`
        ]),
        ...mapState(`productStore`, [
            `notificationDetails`,
            `language`
        ]),
        ...mapGetters(`productStore`, [
            `notificationDetails`,
            `language`
        ])
    },
};
</script>

<style lang="scss">
// for hiding the adjusted risk score section
.mds-layout-grid__row___mwc-fm:nth-child(1) .mds-layout-grid__col___mwc-fm:nth-child(2) {
    display: none;
}

.next-button {
    float: right;
}

.results-wrapper {
    text-align: left;
}
@import "~mwc-fm-results-simplified/dist/mwc-fm-results-simplified.css";
@import "~mwc-esg-results/dist/mwc-esg-results.css";
</style>