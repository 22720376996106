var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "results-wrapper" },
    [
      _vm.sourceAPI === _vm.finametricaSourceAPI
        ? _c("mwc-fm-results-simplified", {
            attrs: {
              "title-hidden": false,
              region: _vm.region,
              "mwc-id": _vm.resultComponent,
              "response-id": this.fmrtqResponseId,
              "client-id": this.fmrtqClientId,
              "risk-tolerance-view": _vm.riskToleranceView,
              languageId: _vm.lang,
            },
          })
        : _vm._e(),
      _vm.sourceAPI === _vm.esgSourceAPI
        ? _c("mwc-esg-results", {
            attrs: {
              "mwc-id": _vm.resultComponent,
              "response-id": this.fmrtqResponseId,
              "client-id": this.fmrtqClientId,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }